<template :id="'CtvdTrajectoryTrend' + this.widgetId">
  <div @contextmenu="this.setContextIdWidget">
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 25 + 'px',
        left: this.mouse_x + 'px',
      }"
      :id="'tooltip' + this.widgetId"
    >
      <div v-if="isTvdTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdTooltipValues.split('</br>')">
          {{ msg }}
        </p>
      </div>
    </div>
    <span class="hidden">{{ darkDark }}</span>
    <div
      class="shadow-lg"
      :style="{
        background:
          darkDark !== 'white'
            ? gridBackground || 'var(--navBar2Bg)'
            : gridBackground || '#FFFFF7',
        transition: 'var(--transition)',
      }"
      :id="'tvdTrajectoryTrend' + this.widgetId"
    ></div>
  </div>
</template>

<script>
import {
  select,
  selectAll,
  scaleLinear,
  axisBottom,
  axisLeft,
  bisector,
  pointer,
  line,
} from "d3";
import apiService from "../../../api/services";
import helperServices from "../../../helper-services"

var svg = "";
export default {
  name: "VerticalTrendWidget",
  components: {},
  props: {
    xAutoScale: {
      type: [Boolean],
      default: false,
    },
    yAutoScale: {
      type: [Boolean],
      default: false,
    },
    xRangeStart: {
      type: [Number, String, undefined],
      default: 0,
    },
    xRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    yRangeStart: {
      type: [Number, String, undefined],
      default: 0,
    },
    yRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    openConfig: Function,
    displayId: String,
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 200,
    },
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "var(--textColor)",
    },
    yAxisLabel: {
      type: String,
      default: "var(--textColor)",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "#00d15a",
    },
    unit: {
      type: String,
      default: "",
    },
    unit_conversion_factor: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      isTvdTooltip: false,
      tvdTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      megaData: [],
      megaData1: [],
      width: this.widgetWidth,
      height: this.widgetHeight,
      graphData: {
        rows: [],
        rowsPlan: [],
        columns: [],
      },
      selectedTags: {
        x: "vertical section",
        y: "tvd",
        z: "md",
      },
      actualLegend: "visible",
      planLegend: "visible",
      dmea_units: null,
    };
  },
  computed: {
    darkDark() {
      // if (this.$store.state.rect.darkmode) {

      //   this.$store.dispatch("rect/lineGraphYAxis", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "white",
      //   });
      //   this.$store.dispatch("rect/lineGraphXAxis", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "white",
      //   });

      //   // valueColor
      // } else {
      //   this.$store.dispatch("rect/lineGraphYAxis", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "black",
      //   });
      //   this.$store.dispatch("rect/lineGraphXAxis", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "black",
      //   });
      // }
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    async setting_unit(){
      try{
        const dtls = this.$store.state.disp.displays[this.displayId];
        let mapping = await helperServices.getIndexDetails(dtls.wellId);
        mapping = mapping.data.logs[dtls.logType];
        const tags = mapping.tags;
        const mnemonicIndex = tags.findIndex((t) => t == "dmea");
        const sourceMnemonic = mapping.source[mnemonicIndex];
        this.dmea_units = null;
        for(let type in mapping.mnemonics){
          if(mapping.mnemonics[type].hasOwnProperty(sourceMnemonic)){
            this.dmea_units = mapping.mnemonics[type][sourceMnemonic]
            break;
          }
        }
        if(!this.$store.state.rect.rects[this.idx]?.unit && this.dmea_units?.display_unit){
          this.$store.dispatch("rect/swabSurgeRealTimedata", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            value: this.dmea_units?.display_unit,
            field: 'unit'
          });
        }
      }
      catch(err){
        console.error(err);
      }
    },
    formatNumber(number, from = null) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      let suffixIndex = 0;

      while (Math.abs(number) >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
      }
      if(from == "yaxis" && Math.abs(number) >= 100 && Math.abs(number) <= 999 && suffixes.length-1 > suffixIndex){
        number /= 1000;
        suffixIndex++;
      }

      return number.toFixed(1) + suffixes[suffixIndex];
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async get3DGraphData(dtls) {
      try {
        const response = await apiService.WellServices.getPlanActualData({
          well_id: dtls.wellId,
          wellbore_name: dtls.wellboreId,
        });
        this.graphData = response.data;
      } catch (error) {}

      await this.set3DGraphData();
    },
    set3DGraphData() {
      let key = "vsection";
      let keyY = "tvd";
      if (this.selectedTags.y == "vertical section") {
        keyY = "vsection";
        key = "tvd";
      }
      if (this.graphData.rows && this.graphData.rows.length) {
        if (this.graphData.rows[0].hasOwnProperty("vertSect")) {
          if (this.selectedTags.y == "vertical section") {
            keyY = "vertSect";
            key = "tvd";
          } else {
            key = "vertSect";
            keyY = "tvd";
          }
        }
        if (this.graphData.rows[0].hasOwnProperty("vertsect")) {
          if (this.selectedTags.y == "vertical section") {
            keyY = "vertsect";
            key = "tvd";
          } else {
            key = "vertsect";
            keyY = "tvd";
          }
        }
      }
      this.megaData = this.graphData.rows.map((ele) => {
        return {
          x: ele[key],
          y: ele[keyY],
          z: ele["md"],
          a: ele["dispew"],
          b: ele["incl"],
          c: ele["dispns"],
          d: ele["azi"],
          e: ele["dls"],
        };
      });
      //
      this.megaData1 = this.graphData.rowsPlan.map((ele) => {
        return {
          x: ele[key],
          y: ele[keyY],
          z: ele["md"],
          a: ele["dispew"],
          b: ele["incl"],
          c: ele["dispns"],
          d: ele["azi"],
          e: ele["dogleg"],
        };
      });
      //

      this.megaData = this.megaData.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      this.megaData1 = this.megaData1.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      //
      //

      const allTags = [
        ...this.graphData.columns,
        ...this.graphData.columnsPlan,
      ];
      const tempTags = allTags.map((ele) => ele.field);
      const tags = [...new Set(tempTags)];
      // const tags = this.graphData.columns.map((ele) => ele.field);

      this.$store.dispatch("disp/setTrejectoryTags", {
        display: this.displayId,
        tags,
      });
    },
    async clearSvgHandler() {
      return new Promise((res, rej) => {
        //
        let gauge = select("#tvdTrajectoryTrend" + this.widgetId);
        gauge.selectAll("*").remove();
        svg = "";
        res();
      });
    },
    async buildSVG() {
      if (this.xRangeStart == this.xRangeEnd) return;
      if (this.yRangeStart == this.yRangeEnd) return;
      var margin = { top: 10, right: 40, bottom: 50, left: 60 },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;

      let xlabelFontSize = this.xAxisLabelSize || 12;
      let ylabelFontSize = this.yAxisLabelSize || 12;
      let alabelFontSize = 12;
      let blabelFontSize = 12;
      let tooltipFontSize = 10;

      //create base svg
      svg = select("#tvdTrajectoryTrend" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "tvdTrajectoryTrend" + this.widgetId);
      }
      svg = select("#tvdTrajectoryTrend" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right - 20)
        .attr("height", height + margin.top + margin.bottom)
        // .style("overflow", "visible")
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      //structure plane trajectory
      var planData = this.megaData1.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          z: parseFloat(d["z"]),
          a: parseFloat(d["a"]),
          b: parseFloat(d["b"]),
          c: parseFloat(d["c"]),
          d: parseFloat(d["d"]),
          e: parseFloat(d["e"]),
          name: "PT",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });

      //structure current trajectory
      var actualData = this.megaData.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          z: parseFloat(d["z"]),
          a: parseFloat(d["a"]),
          b: parseFloat(d["b"]),
          c: parseFloat(d["c"]),
          d: parseFloat(d["d"]),
          e: parseFloat(d["e"]),
          name: "CT",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });

      //create axis
      if (this.xAutoScale) {
        minX = this.xRangeStart;
        maxX = this.xRangeEnd;
      }
      if (this.yAutoScale) {
        //
        minY = this.yRangeStart;
        maxY = this.yRangeEnd;
      }

      if (this.unit_conversion_factor && this.unit_conversion_factor != "") {
        const newMinY = minY * this.unit_conversion_factor;
        const newMaxY = maxY * this.unit_conversion_factor;
        const newMinX = minX * this.unit_conversion_factor;
        const newMaxX = maxX * this.unit_conversion_factor;
        minX = newMinX;
        maxX = newMaxX;
        minY = newMinY;
        maxY = newMaxY;
      }
      var x = scaleLinear()
        .domain([minX, maxX])
        .range([0, width - 10]);
      var y = scaleLinear().domain([maxY, minY]).range([height, 0]);

      var xAxis, yAxis;
      let xTicksArray = [];
      let yTicksArray = [];
      if (this.xAutoScale) {
        let tickInterval = parseFloat(maxX - minX) / 10;
        for (
          let tick = parseFloat(minX);
          tick <= maxX;
          tick = tick + tickInterval
        ) {
          if (xTicksArray.length <= 9) xTicksArray.push(tick);
        }
        xTicksArray.push(maxX);

        let xTicks = axisBottom().scale(x).tickValues(xTicksArray);
        xAxis = xTicks;
        // xAxis = axisBottom(x).ticks();
      } else {
        x.nice();
        xAxis = axisBottom(x).ticks();
      }
      if (this.yAutoScale) {
        let tickInterval = parseFloat((maxY - minY) / 10);
        for (
          let tick = parseFloat(minY);
          tick <= maxY;
          tick = tick + tickInterval
        ) {
          if (yTicksArray.length <= 9) yTicksArray.push(tick);
        }
        yTicksArray.push(maxY);
        let yTicks = axisLeft().scale(y).tickValues(yTicksArray);
        yAxis = yTicks;
        // yAxis = axisLeft(y).ticks();
      } else {
        y.nice();
        yAxis = axisLeft(y).ticks();
      }

      //create grid axis
      let xAxisGrid, yAxisGrid;
      xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      yAxisGrid = axisLeft(y)
        .tickSize(-width + 10)
        .tickFormat("")
        .ticks();
      if (this.xAutoScale) {
        xAxisGrid = axisBottom(x)
          .tickSize(-height)
          .tickFormat("")
          .tickValues(xTicksArray);
      }
      if (this.yAutoScale) {
        yAxisGrid = axisLeft(y)
          .tickSize(-width + 10)
          .tickFormat("")
          .tickValues(yTicksArray);
      }

      // Create grids.
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(xAxisGrid);

      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(yAxisGrid);
      svg
        .selectAll("g.axis-grid")
        .selectAll("line")
        .style("stroke", this.gridColor);
      svg
        .selectAll("g.axis-grid")
        .selectAll("text")
        .style("fill", this.gridColor);
      // Create axes.
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", 12)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .attr("transform", "rotate(-20)")
        .text((d)=>{
          return this.formatNumber(d);
        });
      svg.append("g").attr("class", "yAxis").style("font-size", 12).call(yAxis);
      svg
        .selectAll(".yAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .attr("transform", "rotate(-20)")
        .text((d)=>{
          return this.formatNumber(d, "yaxis");
        });

      let xtext = svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", xlabelFontSize)
        // .style("font-weight", "500")
        .text(
          this.selectedTags.x.toUpperCase() +
            ` (${this.unit && this.unit != "" ? this.unit : "ft"})`
        );

      let xtextWidth = xtext.node().getBBox().width;
      xtext.attr("x", width / 2 + xtextWidth / 2);

      let ytext = svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("dy", -46)
        .attr("dx", -height / 2)
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", ylabelFontSize)
        // .style("font-weight", "500")
        .text(
          this.selectedTags.y.toUpperCase() +
            ` (${this.unit && this.unit != "" ? this.unit : "ft"})`
        );

      //   .text(this.selectedTags.y.toUpperCase() + " (ft)");
      let textHeight = ytext.node().getBBox().width;
      ytext.attr("dx", -height / 2 + textHeight / 2);
      let realTimeTooltip = (event, data, isShow, dataFor) => {
        //
        this.mouse_x = event.clientX + 20;
        this.mouse_y = event.clientY - 70;
        let text = "";

        // text = `${dataFor}</br>${this.selectedTags.x.toUpperCase()} : ${data[0][
        //   "x"
        // ].toFixed(2)} </br>${this.selectedTags.y.toUpperCase()} : ${data[0][
        //   "y"
        // ].toFixed(2)} </br>${this.selectedTags.z.toUpperCase()} : ${data[0][
        //   "z"
        // ].toFixed(2)} `;

        // if (data[0]["a"]) text += `</br>DISPEW : ${data[0]["a"].toFixed(2)}`;
        // if (data[0]["c"]) text += `</br>DISPNS : ${data[0]["c"].toFixed(2)}`;
        // if (data[0]["b"]) text += `</br>INCL : ${data[0]["b"].toFixed(2)}`;

        // MD INC AZM TVD VS NS EW DLS - format-------- dls or dogleg

        text = `${dataFor}</br>${this.selectedTags.z.toUpperCase()} : ${data[0][
          "z"
        ].toFixed(2)}`;
        if (data[0]["b"]) text += `</br>INCL : ${data[0]["b"].toFixed(2)}`;
        if (data[0]["d"]) text += `</br>AZI : ${data[0]["d"].toFixed(2)}`;
        text += `</br>${this.selectedTags.y.toUpperCase()} : ${data[0][
          "y"
        ].toFixed(2)}`;
        text += `</br>${this.selectedTags.x.toUpperCase()} : ${data[0][
          "x"
        ].toFixed(2)}`;
        if (data[0]["c"]) text += `</br>DISPNS : ${data[0]["c"].toFixed(2)}`;
        if (data[0]["a"]) text += `</br>DISPEW : ${data[0]["a"].toFixed(2)}`;
        if (data[0]["e"]) text += `</br>DLS : ${data[0]["e"].toFixed(2)}`;

        this.tvdTooltipValues = text;
        //
        this.isTvdTooltip = isShow;
        //
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        // if (this.tooltipTimeOut) {
        //   clearTimeout(this.tooltipTimeOut);
        // }
        // this.tooltipTimeOut = setTimeout(() => {
        this.mouse_x = 0;
        this.mouse_y = 0;
        this.tvdTooltipValues = "";
        this.isTvdTooltip = isShow;
        // }, 1000);
      };

      //create planned line chart
      let filteredPlanData = planData.filter((d) => {
        return !isNaN(d.x) && d.x >= minX && maxY >= d.y && d.y >= minY;
      });
      if (this.unit_conversion_factor && this.unit_conversion_factor != "") {
        const unitMappedData = filteredPlanData.map((e) => {
          return {
            ...e,
            x: e.x * this.unit_conversion_factor,
            y: e.y * this.unit_conversion_factor,
          };
        });
        filteredPlanData = unitMappedData;
      }
      svg
        .append("path")
        .datum(filteredPlanData)
        .attr("fill", "none")
        .attr("stroke", this.planColor)
        .attr("stroke-width", 2)
        .attr("class", "vertical_plan_line")
        .attr("visibility", this.planLegend)
        // .style("stroke-dasharray", "3, 3")
        .attr(
          "d",
          line()
            .x(function (d) {
              if (maxX >= d.x && d.x >= minX) return x(d.x);
            })
            .y(function (d) {
              if (maxY >= d.y && d.y >= minY) return y(d.y);
            })
        );

      if (this.planLegend == "visible") {
        svg
          .append("g")
          .selectAll("dot")
          .data(filteredPlanData)
          .enter()
          .append("circle")
          .attr("cx", function (d) {
            return x(d.x);
          })
          .attr("cy", function (d) {
            return y(d.y);
          })
          .attr("r", 4)
          .style("fill", "green")
          .attr("visibility", this.planLegend)
          .attr("class", "plan_dot")
          .attr("opacity", 0)
          .on("mouseover", function (e) {
            let data = select(this);
            data.attr("opacity", 1);
            realTimeTooltip(e, data.data(), true, "Plan");
          })
          .on("mousemove", function (e) {
            let data = select(this);
            data.attr("opacity", 1);
            realTimeTooltip(e, data.data(), true, "Plan");
          })
          .on("mouseout", function (e) {
            let data = select(this);
            data.attr("opacity", 0);
            hideRealTimeTooltip(e, "", false);
          });
        //create actual line chart
        let filteredData = actualData.filter((d) => {
          return (
            !isNaN(d.x) &&
            d.x >= minX &&
            d.x <= maxX &&
            maxY >= d.y &&
            d.y >= minY
          );
        });
        if (this.unit_conversion_factor && this.unit_conversion_factor != "") {
          const unitMappedData = filteredData.map((e) => {
            return {
              ...e,
              x: e.x * this.unit_conversion_factor,
              y: e.y * this.unit_conversion_factor,
            };
          });
          filteredData = unitMappedData;
        }
        svg
          .append("path")
          .datum(filteredData)
          .attr("fill", "none")
          .attr("stroke", this.actualColor)
          .attr("stroke-width", 2)
          .attr("class", "vertical_actual_line")
          .attr("visibility", this.actualLegend)
          .attr(
            "d",
            line()
              .x(function (d) {
                if (maxX >= d.x && d.x >= minX && maxY >= d.y && d.y >= minY) {
                  //
                  return x(d.x);
                }
              })
              .y(function (d) {
                if (maxY >= d.y && d.y >= minY && maxX >= d.x && d.x >= minX) {
                  //
                  return y(d.y);
                }
              })
          );
        // .on("mouseover", function (e) {
        //   let data = select(this).data()[0];
        //   var bisect = bisector(function (d) {
        //     return d.x;
        //   }).left;
        //   var bisectY = bisector(function (d) {
        //     return d.y;
        //   }).left;
        //   let x0 = x.invert(pointer(e)[0]);
        //   let y0 = y.invert(pointer(e)[1]);
        //   let i = bisect(data, x0, 1);
        //   let j = bisectY(data, y0, 1);
        //   let d0 = data[i] || data[j];
        //   if (data[j] || data[i]) {

        svg
          .append("g")
          .selectAll("dot")
          .data(filteredData)
          .enter()
          .append("circle")
          .attr("cx", function (d) {
            return x(d.x);
          })
          .attr("cy", function (d) {
            return y(d.y);
          })
          .attr("r", 4)
          .style("fill", "red")
          .attr("class", "actual_dot")
          .attr("visibility", this.actualLegend)
          .attr("opacity", 0)
          .on("mouseover", function (e) {
            let data = select(this);
            data.attr("opacity", 1);
            realTimeTooltip(e, data.data(), true, "Actual");
          })
          .on("mousemove", function (e) {
            let data = select(this);
            data.attr("opacity", 1);
            realTimeTooltip(e, data.data(), true, "Actual");
          })
          .on("mouseout", function (e) {
            let data = select(this);
            data.attr("opacity", 0);
            hideRealTimeTooltip(e, "", false);
          });
      }
      // Set the gradient
      // svg
      //   .append("linearGradient")
      //   .attr("id", "line-gradient")
      //   .attr("gradientUnits", "userSpaceOnUse")
      //   .attr("x1", 0)
      //   .attr("y1", y(0))
      //   .attr("x2", 0)
      //   .attr("y2", y(maxY))
      //   .selectAll("stop")
      //   .data([
      //     { offset: "0%", color: "blue" },
      //     { offset: "10%", color: "green" },
      //     { offset: "40%", color: "brown" },
      //     { offset: "70%", color: this.planColor },
      //     { offset: "100%", color: this.actualColor },
      //   ])
      //   .enter()
      //   .append("stop")
      //   .attr("offset", function (d) {
      //     return d.offset;
      //   })
      //   .attr("stop-color", function (d) {
      //     return d.color;
      //   });
      let g_for_vs_actual_legend = svg
        .append("g")
        .attr("class", "actualText")
        .style("opacity", this.actualLegend === "visible" ? 1 : 0.5);
      let g_for_vs_plan_legend = svg
        .append("g")
        .attr("class", "planText")
        .style("opacity", this.planLegend === "visible" ? 1 : 0.5);
      g_for_vs_actual_legend
        .append("rect")
        .attr("class", "x label actualDot")
        .attr("text-anchor", "end")
        .attr("ry", 20)
        .attr("x", width - 105)
        .attr("y", height + 32)
        .attr("width", 10)
        .attr("height", 10)
        .style("cursor", "pointer")
        .style("fill", this.actualColor);
      g_for_vs_actual_legend
        .append("text")
        .attr("class", "x label actualText")
        .attr("text-anchor", "end")
        .attr("x", width - 50)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", alabelFontSize)
        .style("cursor", "pointer")
        .text("Actual");

      g_for_vs_plan_legend
        .append("rect")
        .attr("class", "x label planDot")
        .attr("text-anchor", "end")
        .attr("ry", 20)
        .attr("x", width - 40)
        .attr("y", height + 32)
        .attr("width", 10)
        .attr("height", 10)
        .style("cursor", "pointer")
        .style("fill", this.planColor);

      g_for_vs_plan_legend
        .append("text")
        .attr("class", "x label planText")
        .attr("text-anchor", "end")
        .attr("x", width + 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", blabelFontSize)
        .style("cursor", "pointer")
        .text("Plan");

      g_for_vs_actual_legend.on("click", (d) => {
        if (select(".vertical_actual_line").attr("visibility") == "visible") {
          this.actualLegend = "hidden";
          select(".vertical_actual_line").attr("visibility", this.actualLegend);
          selectAll(".actual_dot").filter(function () {
            return select(this).attr("visibility", "hidden");
          });
          select(".actualText").style("opacity", 0.5);
        } else {
          this.actualLegend = "visible";
          select(".vertical_actual_line").attr("visibility", this.actualLegend);
          selectAll(".actual_dot").filter(function () {
            return select(this).attr("visibility", "visible");
          });
          select(".actualText").style("opacity", 1);
        }
        // this.buildSVG();
      });
      g_for_vs_plan_legend.on("click", (d) => {
        if (select(".vertical_plan_line").attr("visibility") == "visible") {
          this.planLegend = "hidden";
          select(".vertical_plan_line").attr("visibility", this.planLegend);
          selectAll(".plan_dot").filter(function () {
            return select(this).attr("visibility", "hidden");
          });
          select(".planText").style("opacity", 0.5);
        } else {
          this.planLegend = "visible";
          select(".vertical_plan_line").attr("visibility", this.planLegend);
          selectAll(".plan_dot").filter(function () {
            return select(this).attr("visibility", "visible");
          });
          select(".planText").style("opacity", 1);
        }
        // this.buildSVG();
      });
    },
    dark() {
      this.$emit("dark");
    },
    light() {
      this.$emit("light");
    },
  },
  watch: {
    xAutoScale: async function (newVal, oldVal) {
      this.buildSVG();
    },
    xRangeStart: async function (newVal, oldVal) {
      if (this.xAutoScale) {
        if (isNaN(parseInt(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (parseInt(this.xRangeEnd) < parseInt(newVal)) {
            this.$toast.error(
              "X-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    xRangeEnd: async function (newVal, oldVal) {
      if (this.xAutoScale) {
        if (isNaN(parseInt(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (parseInt(this.xRangeStart) >= parseInt(newVal)) {
            this.$toast.error(
              "X-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    yAutoScale: async function (newVal, oldVal) {
      this.buildSVG();
    },

    yRangeStart: async function (newVal, oldVal) {
      if (this.yAutoScale) {
        if (isNaN(parseInt(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (
            parseInt(this.yRangeEnd) < parseInt(newVal) ||
            parseInt(this.yRangeEnd) === parseInt(newVal)
          ) {
            this.$toast.error(
              "Y-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    yRangeEnd: async function (newVal, oldVal) {
      if (this.yAutoScale) {
        if (isNaN(parseInt(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (parseInt(this.yRangeStart) >= parseInt(newVal)) {
            this.$toast.error(
              "Y-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    isSwitched: async function (newVal, oldVal) {
      try {
        let prevTags = { ...this.selectedTags };
        this.selectedTags.x = prevTags.y;
        this.selectedTags.y = prevTags.x;
        this.set3DGraphData();
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    selectedTags: {
      handler(val) {
        this.set3DGraphData();
        this.buildSVG();
      },
      deep: true,
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    unit_conversion_factor(val) {
      if (val && val != "") {
        this.buildSVG();
      }
    },
  },
  beforeUnmount() {
    try {
      clearInterval(this.loadVerticalInterval);
    } catch (error) {}
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      this.topic = dtls.topicName;
      await this.setting_unit();
      await this.get3DGraphData(dtls);
    }
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "rect/LINE_GRAPH_MNEMONIC_CHANGE") {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.selectedTags = {
                x: this.$store.state.rect.rects[i].mnemonicTag1 || "dispew",
                y: this.$store.state.rect.rects[i].mnemonicTag2 || "tvd",
                z: "md",
              };
            }
          }
        }
      }

      if (mutation.type == "disp/setDisplay") {
        if (mutation.payload.display == this.displayId) {
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.wellboreState = mutation.payload.wellboreState;
          this.logType = mutation.payload.logType;
          this.table = mutation.payload.table;
          this.topic = mutation.payload.topicName;
          await this.setting_unit();
          await this.get3DGraphData(mutation.payload);
          this.buildSVG();
          try {
            clearInterval(this.loadVerticalInterval);
            this.loadVerticalInterval = setInterval(async () => {
              await this.get3DGraphData(mutation.payload);
              this.buildSVG();
            }, 60000);
          } catch (error) {}
        }
      }
    });

    this.buildSVG();

    try {
      clearInterval(this.loadVerticalInterval);
      this.loadVerticalInterval = setInterval(async () => {
        await this.get3DGraphData(dtls);
        this.buildSVG();
      }, 60000);
    } catch (error) {}
  },
};
</script>

<style scoped>
.overlay {
  fill: none;
  pointer-events: all;
}

.focus circle {
  fill: rgb(0, 0, 0);
}

.focus text {
  font-size: 14px;
}

.tooltip {
  fill: white;
  stroke: rgb(255, 249, 249);
}

.tooltip-date,
.tooltip-likes {
  font-weight: bold;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}
</style>
